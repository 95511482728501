import React, {useEffect} from "react";
import { Link } from "gatsby";
import {Helmet} from "react-helmet";
import {Box, Card, Flex, Heading, Image, Text, Link as ThemedLink} from "theme-ui";
import AuthenticatedDataView from "../../components/AuthenticatedDataView";
import SearchListingsButton from "../../components/SearchListingsButton";
import ShareButton from "../../components/ShareButton";
import {endpoint} from "../../lib/endpoint";

type Venue = {
  id: number,
  name: string,
  latitude: number,
  longitude: number
}

type Listing = {
  guid: string,
  title: string,
  description?: string,
  humanized_price: string,
  image_card_url: string,
  listed_time_ago_in_words: string,
  venue: Venue
};

const ListingView = ({ listing }: { listing: Listing }) => {

  useEffect(() => {
    // TODO: this is not effective once deployed
    // document.title = `FreshlyShopped - ${listing.title}`;
    // return () => { document.title = '' };
  }, [listing]);

  return <Card variant="listing">
    <Helmet>
      <meta charSet="utf-8" />
      <title>FreshlyShopped - {listing.title} | {listing.humanized_price}</title>
      <link rel="canonical" href={`https://links.freshlyshopped.com/deeplink/listings/${listing.guid}`} />
    </Helmet>
    <Flex sx={{ flexDirection: "row", justifyContent: "center" }} >
      <Image src={listing.image_card_url} variant="listingCard" />
    </Flex>
    <Heading sx={{ pt: 2 }}>
      {listing.title} | {listing.humanized_price}
    </Heading>
    { listing.description &&
      <Box mt={3}>
        <Text>
          {listing.description}
        </Text>
      </Box>
    }
    <Box mt={3}>
      <Text>
        At {listing.venue.name} -{" "}
        <ThemedLink as={Link} to={`/listings-at-location?lat=${listing.venue.latitude}&lon=${listing.venue.longitude}`}>See nearby listings</ThemedLink>
      </Text>
    </Box>
    <Box mt={3}>
      <Text>Listed {listing.listed_time_ago_in_words} ago</Text>
    </Box>
    <Box mt={3}>
      <ShareButton title="Better Shop Together" text={`Check this special: ${listing.title}`} url={`https://links.freshlyshopped.com/deeplink/listings/${listing.guid}`} onClick={() => { console.log("shared"); global.alert(`You successfully shared: ${listing.title}`); }} />
    </Box>
    <Box pt={3}>
    </Box>
  </Card>
}

export default ({ params }) => {
  console.log("params", params);
  return <>
    <AuthenticatedDataView fetchUrl={`${endpoint}/listings/${params.id}`}
      render={data => {
        const {listing} = data;
        if (!listing) {
          return (
            <>
              <Heading sx={{py: 3}} as="h3">Oops, No Such Listing Found</Heading>
              <Box sx={{pb: 4}}>
                <Text>Maybe the listing is no longer valid?</Text>
              </Box>
              <SearchListingsButton />
            </>

          );
        }
        return <>
          <ListingView listing={data.listing as Listing} />
          {/* <div>data: ${JSON.stringify(data)}</div> */}
        </>;
      }}
    />
  </>;
}
