import React from "react";
import { RWebShare } from "react-web-share";
import IosShareIcon from "@mui/icons-material/IosShare";
import ShareIcon from "@mui/icons-material/Share";
import ActionButton from "./ActionButton";
import useIsIOS from "../lib/useIsIOS";

type EditButtonProps = {
  text: string,
  url: string,
  title: string,
  onClick: () => void
}

export default (props: EditButtonProps) => {
  const isIos = useIsIOS();
  const { text, url, title, onClick } = props;
  return (
    <RWebShare
      data={{
        text,
        url,
        title,
      }}
      onClick={props.onClick}
    >
      <ActionButton icon={isIos ? <IosShareIcon /> : <ShareIcon />} onClick={onClick} text="Share" />
    </RWebShare>
  );
}
